import React from "react";
import "../../styles/TopBanner.scss";
import { faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Logo from '../../assets/images/Logo.png';
import { Link } from "react-router-dom";
import { Col, Row } from "antd";
export default function TopBanner() {
  return (
    <div className="top-banner-container">
      <Row span={24}>
        <Col span={6}>
        <img src={Logo} /> 
        </Col>
        <Col span={6}>
        <FontAwesomeIcon icon={faPhone} className="top-banner-icon" />
          +9305709740
          </Col>
          <Col span={6}>
          <FontAwesomeIcon icon={faEnvelope} className="top-banner-icon" />
          info@xaviera.com
          </Col>
          <Col span={6}>
          On sale now up to 50% off!
          <Link to="/shop" className="shop_now_link_top_banner">
            Shop now
          </Link>
          </Col>
      </Row>
      {/* <div className="row">
        <div className="col-lg-3">
          <img src={Logo} /> 
        </div>
        <div className="col-lg-3">
          <FontAwesomeIcon icon={faPhone} className="top-banner-icon" />
          +9305709740
        </div>
        <div className="col-lg-3">
          <FontAwesomeIcon icon={faEnvelope} className="top-banner-icon" />
          info@xaviera.com
        </div>
        <div className="col-lg-3">
          On sale now up to 50% off!
          <Link to="/shop" className="shop_now_link_top_banner">
            Shop now
          </Link>
        </div>
      </div> */}
    </div>
  );
}
