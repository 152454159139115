import React, { Suspense, lazy } from "react";
import "./styles/App.scss";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import { GlobalCartContextProvider } from "./context/CartContext";
// import { ToastProvider } from "react-toastify";
import loadingIcon from "./assets/images/dashboardloader3.gif";
import TopBanner from "./Components/Navigation/TopBanner";
import NavBar from "./Components/Navigation/NavBar";
import Footer from "./Components/Navigation/Footer";
const Homepage = lazy(() => import("./Components/Homepage"));

//import Homepage from "./Components/Homepage";

const Shopping = lazy(() => import("./Components/Shopping"));
const Cart = lazy(() => import("./Components/Cart/Cart"));
const Catalog = lazy(() => import("./Components/Catalog"));

const SearchProducts = lazy(() => import("./Components/SearchProducts"));
const PageNotFound = lazy(() => import("./Components/PageNotFound"));

function App() {
  return (
        <GlobalCartContextProvider>
      {/* <ToastProvider> */}
        <Router>
          <Suspense
            fallback={
              <img src={loadingIcon} alt="loading" className="loadingIcon" />
            }
          >
       <TopBanner />
        <NavBar />
            <Routes>
              <Route exact path="/" element={<Homepage />} />
              <Route exact path="/shop" element={<Shopping />} />
              <Route exact path="/search" element={<SearchProducts />} />
              <Route exact path="/cart" element={<Cart />} />
              <Route exact path="/trend/:collectionname" element={<Shopping />} />
              <Route exact path="/collections" element={<Shopping />} />
              <Route
                exact
                path="/collections/:collectionname"
                element={<Shopping />}
              />
              <Route
                exact
                path="/catalog/item/:id/:productname/:action"
                element={<Catalog />}
              />

              <Route element={<PageNotFound />} />
            </Routes>
            <Footer />
          </Suspense>
        </Router>
      {/* </ToastProvider> */}
    </GlobalCartContextProvider>
  );
}

export default App;
